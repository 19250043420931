require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

const initMainNav = () => {
  const hamgurger = document.getElementById('main-nav-toggle');
  const mainNav = document.querySelector('.main-nav');

  hamgurger.addEventListener('click', () => {
    hamgurger.classList.toggle('is-active');
    mainNav.classList.toggle('main-nav--open');
    document.body.classList.toggle('main-nav-open')
  });

  for (const subnav of mainNav.querySelectorAll('.main-nav__list .main-nav__list')) {
    subnav.parentElement.querySelector('a').addEventListener('click', (e) => {
      if (window.innerWidth < 992) {
        e.preventDefault();

        e.currentTarget.parentElement.classList.toggle('main-nav__list--open');
      }
    });
  }
};

const initSliders = () => {
  new Swiper('#categories-swiper .swiper-container', {
    loop: true,
    slidesPerView: 2,
    breakpoints: {
      600: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
    },
    navigation: {
      nextEl: document.querySelector('#categories-swiper .swiper-button-next'),
      prevEl: document.querySelector('#categories-swiper .swiper-button-prev'),
    },
  });

  new Swiper('#certificates-swiper .swiper-container', {
    loop: true,
    slidesPerView: 2,
    breakpoints: {
      600: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 7,
      },
    },
    navigation: {
      nextEl: document.querySelector('#certificates-swiper .swiper-button-next'),
      prevEl: document.querySelector('#certificates-swiper .swiper-button-prev'),
    },
  });

  new Swiper('#gallery-swiper .swiper-container', {
    loop: true,
    slidesPerView: 1,
    navigation: {
      nextEl: document.querySelector('#gallery-swiper .swiper-button-next'),
      prevEl: document.querySelector('#gallery-swiper .swiper-button-prev'),
    },
    on: {
      slideChange: function (swiper) {
        const activeSlide = swiper.slides[swiper.activeIndex];
        const id = activeSlide.getAttribute('data-id');

        for (const description of document.querySelectorAll('#gallery-swiper .gallery__description')) {
          if (description.getAttribute('data-id') === id) {
            description.style.display = 'block';
          } else {
            description.style.display = 'none';
          }
        }
      },
    }
  });

  if (document.querySelectorAll('#hero-swiper .swiper-slide').length > 1) {
    new Swiper('#hero-swiper .swiper-container', {
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: document.querySelector('#hero-swiper .swiper-button-next'),
        prevEl: document.querySelector('#hero-swiper .swiper-button-prev'),
      },
    });
  }
};

const initHeaderOffset = () => {
  const header = document.querySelector('.header');
  const logo = document.querySelector('.header__logo');
  const offset = Math.max(30, logo.offsetHeight - header.offsetHeight + 30);

  const element = document.querySelector('.-header-offset');

  if (element) {
    element.style.marginTop = `${offset}px`;
  }
};

document.addEventListener('turbolinks:load', () => {
  initMainNav();
  initSliders();
  initHeaderOffset();

  window.addEventListener('resize', initHeaderOffset);
});